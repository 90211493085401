import PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Columns } from 'react-bulma-components'

function ApplyNow({ data }) {
  return (
    <Columns className="is-flex-mobile is-vcentered">
      <Columns.Column className="is-offset-3 is-1">
        {data.datoCmsCareer.author &&
        data.datoCmsCareer.author.mainImage.gatsbyImageData ? (
          <GatsbyImage
            image={data?.datoCmsCareer?.author?.mainImage?.gatsbyImageData}
            alt={data?.datoCmsCareer?.author?.mainImage?.alt || 'Pocketeers'}
          />
        ) : (
          <br />
        )}
      </Columns.Column>
      <Columns.Column className="is-6 is-size-6 ">
        <h4>
          Interested? Please{' '}
          <a href="mailto:careers@pocketworks.co.uk">
            email {data.datoCmsCareer.author.name}
          </a>{' '}
          to setup a chat. {data.datoCmsCareer.date}
        </h4>
      </Columns.Column>
    </Columns>
  )
}
ApplyNow.propTypes = {
  data: PropTypes.object,
}

export default ApplyNow
