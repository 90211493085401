import PropTypes from 'prop-types'
import React from 'react'
import { Hero, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'

function CareerHero({ data }) {
  return (
    <Hero
      className="has-bg-image is-medium is-success blog-hero"
      style={{
        backgroundImage: `url(${
          data.datoCmsCareer.featuredMedia
            ? data.datoCmsCareer.featuredMedia.fluid.src
            : ''
        }&bri=-10&blend=https%3A%2F%2Fassets.imgix.net%2Fblog%2Fblog-blends.pdf%3Fpage%3D1%26fm%3Dpng&blend-mode=multiply&blend-w=1500&blend-h=1000&blend-fit=crop&blend-align=left,center&blend-crop=left,center&fit=facearea&w=1000&h=500&facepad=7.0)`,
      }}
      id="blog-hero"
    >
      <div className="background-overlay">
        <Hero.Header>
          <SiteNav className="is-light" fullLogo />
        </Hero.Header>
        <Container>
          <Hero.Body>
            <Container className="content">
              <Columns className="is-tablet is-vcentered">
                <Columns.Column className="is-offset-2 is-8 has-text-centered">
                  <h2>
                    <small className="subtitle is-size-2">
                      Join Pocketworks
                    </small>
                  </h2>
                  <h1
                    itemProp="title"
                    className="title is-top-marginless is-size-1 is-size-3-mobile"
                  >
                    {data.datoCmsCareer.title}
                  </h1>
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        </Container>
      </div>
    </Hero>
  )
}
CareerHero.propTypes = { data: PropTypes.object }

export default CareerHero
