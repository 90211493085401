import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function JobDescription({ data }) {
  return (
    <Columns>
      <Columns.Column className="is-6 is-offset-3">
        {data.datoCmsCareer.isLive === true ? (
          ''
        ) : (
          <div className="tag is-rounded is-danger is-size-3 mb-6">
            Sorry, {data.datoCmsCareer.isLive} this position is now closed 😢
          </div>
        )}
        <div
          itemProp="description"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsCareer.contentNode.childMarkdownRemark.html,
          }}
        />
      </Columns.Column>
    </Columns>
  )
}
JobDescription.propTypes = { data: PropTypes.object }

export default JobDescription
