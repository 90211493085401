/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// BlogCategoryPage Route
import Career from 'routes/templates/career'

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.datoCmsCareer.seoMetaTags.tags} />
}
export default Career

export const query = graphql`
  query CareerQuery($slug: String!) {
    datoCmsCareer(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      isLive
      slug
      dateCreated
      closeDate
      fontawesomeIconName
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      featuredMedia {
        url
        fluid(maxWidth: 1024, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
      author {
        name
        role
        mainImage {
          url
          alt
          gatsbyImageData(
            placeholder: TRACED_SVG
            imgixParams: {
              mask: "ellipse"
              fm: "jpg"
              auto: "compress"
              fit: "facearea"
              facepad: 3.0
              maxW: 200
            }
          )
        }
      }
    }
  }
`
