import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import Layout from 'components/layout'
// Page Components
import CareerHero from './components/career-hero'
import JobPosition from './components/job-position'
import JobDescription from './components/job-description'
import ApplyNow from './components/apply-now'
import Instagram from './components/instagram'

function Career({ data }) {
  return (
    <Layout>
      <div
        className="article"
        itemScope
        itemType="https://schema.org/JobPosting"
      >
        <CareerHero data={data} />
        <Section>
          <Container className="content">
            <JobPosition data={data} />
            <hr />
            <JobDescription data={data} />
            <hr />
            <ApplyNow data={data} />
          </Container>
        </Section>
        <Instagram data={data} />
      </div>
    </Layout>
  )
}
Career.propTypes = { data: PropTypes.object }
export default Career
