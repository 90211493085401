// import PropTypes from 'prop-types'
import React from 'react'
import { Section } from 'react-bulma-components'

function Instagram() {
  // function Instagram({ data }) {
  return (
    <Section className="is-paddingless instagram-strip">
      {/* <Columns className="is-mobile is-gapless">
          {data.instagram.edges.map(({ node: instaPost }) => (
            <Columns.Column className="is-1" key={instaPost.id}>
              <a
                href={`https://www.instagram.com/p/${instaPost.id}/`}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  className="instagram-photo"
                  fluid={instaPost.localFile.childImageSharp.fluid}
                  title={instaPost.caption}
                />
              </a>
            </Columns.Column>
          ))}
        </Columns> */}
    </Section>
  )
}
// Instagram.propTypes = { data: PropTypes.object }

export default Instagram
